import { Column, GridContainer, Row, SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { useIdentity, useSwanStyleKeys } from '@vp/ubik-context'
import AccountSettings from '../infrastructure/views/components/AccountSettings'
import { ProfileContextProvider } from '../contexts/ProfileContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { stylesheet } from '../infrastructure/views/components/SecurityTab/PasswordChange/PasswordForm.scss'
import { useStyles } from '@vp/ubik-context'
export interface Props {
  locale: string
  localizations: { [key: string]: any }
}

export const Fragment = (props: Props) => {
  const { identity, auth } = useIdentity()

  useSwanStyleKeys(myAccountSettingsStyleKeys)
  // Always call useStyles BEFORE any conditional rendering
  // See https://vistaprint.slack.com/archives/C050H537WPQ/p1744029442489659
  useStyles(stylesheet)
  if (identity?.isSignedIn === false) {
    auth?.signIn()
  }

  if (!auth?.getAuthorization()) {
    return <></>
  }

  return (
    <QueryClientProvider client={new QueryClient()}>
      <ProfileContextProvider>
        <GridContainer>
          <Row marginBottom='between-sections'>
            <Column span={12}>
              <AccountSettings />
            </Column>
          </Row>
        </GridContainer>
      </ProfileContextProvider>
    </QueryClientProvider>
  )
}

const myAccountSettingsStyleKeys = [
  SWAN_STYLE_KEY_MAP.spinner,
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.utility,
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.grid,
  SWAN_STYLE_KEY_MAP.selectionSet,
  SWAN_STYLE_KEY_MAP.toggleSwitch,
  SWAN_STYLE_KEY_MAP.standardTile,
  SWAN_STYLE_KEY_MAP.alertBox,
  SWAN_STYLE_KEY_MAP.accordion,
  SWAN_STYLE_KEY_MAP.modalDialog,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.selectionSet,
  SWAN_STYLE_KEY_MAP.tabs,
  SWAN_STYLE_KEY_MAP.progressiveImage,
  SWAN_STYLE_KEY_MAP.hidden,
  SWAN_STYLE_KEY_MAP.visible,
  SWAN_STYLE_KEY_MAP.popover,
  SWAN_STYLE_KEY_MAP.listbox
]
