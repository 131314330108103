import { Column, GridContainer, Row, Typography, useId } from '@vp/swan'
import { useEffect, useState } from 'react'

import ButtonsRow from './ButtonsRow'
import CucosClient from './clients/CucosClient'
import ContactPreferencesDescription from './ContactPreferencesDescription'
import EmailPreferencesSwitch, { EmailPreference } from './EmailPreferencesSwitch'
import { useLocalization } from '../../../../hooks/useLocalizations'
import { useIdentity, useUserContext } from '@vp/ubik-context'

type ContactPreferencesFormProps = {
  userEmail: string;
  onCancel?: () => void;
  onSaveClicked?: (oldStatus: EmailPreference, newStatus: EmailPreference) => void;
  onSendFeedback: (content: string, isSuccess?: boolean) => void;
  onAltered?: (altered: boolean) => void;
  children?: any;
  storedPreference: EmailPreference;
  storedPreferenceError: boolean;
}

const ContactPreferencesForm = (props: ContactPreferencesFormProps) => {
  const { storedPreference, storedPreferenceError } = props
  const { tenant, locale, environment } = useUserContext()
  const { identity } = useIdentity()
  const { t } = useLocalization()
  const randomId = useId()
  const cucosClient = new CucosClient(tenant || 'vistaprint', identity?.authorizationHeader || '', environment || 'prod')

  const [preference, setPreference] = useState<EmailPreference>(storedPreference)
  const [updatingPreference, setUpdatingPreference] = useState(false)

  useEffect(() => {
    if (storedPreferenceError) {
      props.onSendFeedback(t('contactPreferences.pageLoadErrorDialog.errorMessage'), false)
    }
  }, [storedPreferenceError, props, t])

  const isPreferenceInitialized = () => {
    return preference !== 'uninitialized'
  }

  const isUpdateButtonDisabled = () => {
    return !isPreferenceInitialized() || storedPreference === preference
  }

  const submitPreferenceUpdate = () => {
    if (props.onSaveClicked) props.onSaveClicked(storedPreference, preference)

    setUpdatingPreference(true)
    cucosClient.storePreference(props.userEmail, preference).then(onPreferenceUpdateSuccess, onPreferenceUpdateFailure)
  }

  const onPreferenceUpdateSuccess = (pref: EmailPreference) => {
    setUpdatingPreference(false)
    setPreference(pref)

    props.onSendFeedback(t('contactPreferences.preferenceUpdate.successMessage'), true)
  }

  const onPreferenceUpdateFailure = () => {
    setUpdatingPreference(false)
    props.onSendFeedback(t('contactPreferences.preferenceUpdate.failureMessage'), false)
  }
  return (
    <>
      <GridContainer>
        <Row>
          <Column span={12} role='group' aria-labelledby={randomId}>
            <Typography mb='6' id={randomId}>
              <ContactPreferencesDescription tenant={tenant || 'vistaprint'} locale={locale} />
            </Typography>
            <EmailPreferencesSwitch
              onPreferencesChanged={(preference: EmailPreference) => {
                setPreference(preference)
                if (props.onAltered) {
                  props.onAltered(preference !== storedPreference)
                }
              }}
              preference={preference}
            />
            {props.children}
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <ButtonsRow
              onCancel={props.onCancel}
              updatingPreference={updatingPreference}
              isUpdateButtonDisabled={isUpdateButtonDisabled}
              submitPreferenceUpdate={submitPreferenceUpdate}
            />
          </Column>
        </Row>
      </GridContainer>
    </>
  )
}

export default ContactPreferencesForm
