import { useContext, useEffect, useState } from 'react'
import { Row, Column, Typography, Link, AlertBox } from '@vp/swan'
import evaluateLastPasswordChange from './evaluateLastPasswordChange'
import PasswordChange from './PasswordChange/PasswordChange'
import DisplayAlert from '../../../../common/model/displayAlert'
import Section from '../Shared/Section'

import TrackingEventLabels from '../../../../common/model/trackingEventLabels'
import { TabIds } from '../../../../common/model/tabIds'
import { AccountDeletionModal } from './AccountDeletion/AccountDeletionModal'
import SectionGroup, { SectionGroupChild } from '../Shared/SectionGroup'
import { CustomerCareTeamPage } from '../../../../common/model/customerCareTeamUrl'
import useCareTeamUrl from '../../../../hooks/useCareTeamUrl'
import {
  AccountDeletionReason,
  trackAccountDeletionRequest,
} from '../../../../common/services/tracking'
import { useLocalization } from '../../../../hooks/useLocalizations'
import { useLogger } from '@vp/ubik-context'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { Trans } from '../Shared/Trans'

type SecurityProps = {
  displayAlert: DisplayAlert;
  resetSections: boolean;
  parentTabId: TabIds;
  canManagePassword: boolean;
  canDeleteAccount: boolean;
}

function Security (props: SecurityProps) {
  const { displayAlert, canManagePassword, canDeleteAccount } = props
  const { profile, sendAccountDeletionRequest } = useContext(ProfileContext)

  const [deletionInProgress, setDeletionInProgress] = useState(
    profile.requestReceivedForDeletion || false
  )

  const { t } = useLocalization()
  const [lastChangedOn, setLastChangedOn] = useState(
    profile.lastPasswordChangedOn
  )
  const [hasAccountDeletionError, setHasAccountDeletionError] = useState(false)
  const [accountDeletionIsSubmitting, setAccountDeletionIsSubmitting] =
    useState(false)

  const onPasswordChanged = () => setLastChangedOn(new Date().toISOString())
  const customerCareUrl = useCareTeamUrl(CustomerCareTeamPage)
  const careUrlResolved = customerCareUrl !== ''

  const logger = useLogger()

  useEffect(() => {
    setDeletionInProgress(profile.requestReceivedForDeletion || false)
  }, [profile.requestReceivedForDeletion])

  const handleAccountDeletionError = (
    trackingLabel: TrackingEventLabels,
    deleteAccountReason: AccountDeletionReason,
    error: unknown
  ) => {
    setAccountDeletionIsSubmitting(false)
    setHasAccountDeletionError(true)
    trackAccountDeletionRequest(
      trackingLabel,
      deleteAccountReason,
      'deletionRequestFailed'
    )
    logger.error('Submit Account Deletion Error:', { error, profile })
  }

  const submitAccountDeletionRequest = async (
    onClose: () => void,
    deleteAccountReason: AccountDeletionReason
  ) => {
    const trackingLabel = TrackingEventLabels.DELETE_ACCOUNT_REQUEST_SUBMITTED
    setAccountDeletionIsSubmitting(true)
    setHasAccountDeletionError(false)

    try {
      const accountDeletionRequestResponse = await sendAccountDeletionRequest()

      if (accountDeletionRequestResponse.error) {
        const { error } = accountDeletionRequestResponse
        handleAccountDeletionError(trackingLabel, deleteAccountReason, error)
        return
      }

      setAccountDeletionIsSubmitting(false)
      setDeletionInProgress(true)
      onClose()
    } catch (error) {
      handleAccountDeletionError(trackingLabel, deleteAccountReason, error)
      return
    }

    trackAccountDeletionRequest(
      trackingLabel,
      deleteAccountReason,
      'deletionRequestSucceeded'
    )
  }

  const sections: SectionGroupChild[] = []

  if (canManagePassword) {
    sections.push((onToggle, isOpen) => (
      <Section
        data-testid='security-changePassword-link'
        label={t('security.password')}
        canToggle={!profile.readOnlyMode}
        onToggle={onToggle}
        trackingLabel={TrackingEventLabels.EDIT_PASSWORD_CLICKED}
        isOpen={isOpen}
        toggleText={t('security.passwordChange')}
      >
        {{
          whenOpened: (onHide) => (
            <PasswordChange
              onHide={onHide}
              onPasswordChanged={onPasswordChanged}
              displayAlert={displayAlert}
            />
          ),
          whenClosed: (
            <>
              {!isOpen && lastChangedOn && (
                <Row marginTop='3'>
                  <Column span={12}>
                    <Typography
                      data-testid='security-lastPasswordChangedOn'
                      textAlign='left'
                    >
                      {evaluateLastPasswordChange(t, lastChangedOn)}
                    </Typography>
                  </Column>
                </Row>
              )}
            </>
          ),
        }}
      </Section>
    ))
  }
  if (canDeleteAccount) {
    sections.push((onToggle, isOpen) => (
      <Section
        data-testid='security-delete-account-section'
        label={t('security.deleteAccount')}
        canToggle={!deletionInProgress}
        onToggle={onToggle}
        trackingLabel={TrackingEventLabels.DELETE_ACCOUNT_MODAL_OPENED}
        isOpen={isOpen}
        toggleText={t('security.startProcess')}
        // We require a unique key to force re-rendering of the section component
        // because account deletion modal is not getting re-rendered when there is
        // a state change due to the section caching the whenOpened and whenClosed components
        key={`delete_account_section_${hasAccountDeletionError}_${accountDeletionIsSubmitting}_${careUrlResolved}`}
        forceRerenderToggle={deletionInProgress ? 'deletionInProgress' : ''}
      >
        {{
          whenOpened: (onClose: () => void) => (
            <AccountDeletionModal
              isOpen={isOpen}
              onSubmit={(deleteAccountReason) =>
                submitAccountDeletionRequest(onClose, deleteAccountReason)}
              onClose={onClose}
              hasError={hasAccountDeletionError}
              isSubmitting={accountDeletionIsSubmitting}
            />
          ),
          whenClosed: (
            <>
              {hasAccountDeletionError && (
                <AlertBox mt={3} skin='error'>
                  {t('security.deletionError')}
                </AlertBox>
              )}
              {deletionInProgress && (
                <div data-testid='deletion-in-progress-content'>
                  <Typography py='3'>
                    {t('security.deletionInProgress')}
                  </Typography>
                  <Typography>
                    <Trans
                      resourceKey='security.contactCustomerCare'
                      tokens={{
                        customerCareTeamLinkStaticText: t(
                          'security.customerCareTeamLinkStaticText'
                        ),
                      }}
                      components={[
                        <Link href={customerCareUrl} key='customerCareTeamLinkStaticText'>
                          customerCareTeamLinkStaticText
                        </Link>]}
                    />
                  </Typography>
                </div>
              )}
            </>
          ),
        }}
      </Section>
    ))
  }
  return (
    <div data-testid='security-section'>
      <SectionGroup
        resetSections={props.resetSections}
        parentTabId={props.parentTabId}
      >
        {sections}
      </SectionGroup>
    </div>
  )
}

export default Security
